import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { debounceTime, map, takeUntil, tap } from 'rxjs/operators';

import { debounceTimes } from '@oper-client/shared/configuration';
import { DestroyableComponent } from '@shared/util-component';
import { UiColor } from '@oper-client/shared/data-model';

enum ButtonIconPlacement {
	LEFT = 'left',
	RIGHT = 'right',
	CENTER = 'center',
}

enum ButtonClassEnum {
	DEFAULT = 'button--default',
	PRIMARY = 'button--primary',
	SECONDARY = 'button--secondary',
	TERTIARY = 'button--tertiary',
	INFO = 'button--info',
	SUCCESS = 'button--success',
	WARNING = 'button--warning',
	DANGER = 'button--danger',
	FULL_WIDTH = 'button--full-width',
	ACTIVE = 'button--active',
	ARTIFICIAL_INTELLIGENCE = 'button--artificial-intelligence',
}

type ButtonClass = {
	[key in ButtonClassEnum]: boolean;
};

@Component({
	selector: 'oper-client-button',
	styleUrls: ['./button.component.scss'],
	template: `
		<button class="button" [disabled]="isDisabled" [ngClass]="buttonClass" [attr.data-test]="dataTestAttr" (click)="clicked$.next()">
			<oper-client-fontawesome-icon
				*ngIf="iconPlacement === IconPlacement.LEFT"
				class="icon icon--prepend"
				[color]="priority === 'artificial-intelligence' ? 'artificial-intelligence' : 'default'"
				[icon]="icon$ | async"
				[spin]="iconSpin$ | async"
			></oper-client-fontawesome-icon>
			<oper-client-fontawesome-icon
				*ngIf="iconPlacement === IconPlacement.CENTER"
				class="icon icon--center"
				[color]="priority === 'artificial-intelligence' ? 'artificial-intelligence' : 'default'"
				[icon]="icon$ | async"
				[spin]="iconSpin$ | async"
			></oper-client-fontawesome-icon>
			<div class="label" *ngIf="label && iconPlacement !== IconPlacement.CENTER">{{ label }}</div>
			<oper-client-fontawesome-icon
				*ngIf="iconPlacement === IconPlacement.RIGHT"
				class="icon icon--append"
				[color]="priority === 'artificial-intelligence' ? 'artificial-intelligence' : 'default'"
				[icon]="icon$ | async"
				[spin]="iconSpin$ | async"
			></oper-client-fontawesome-icon>
		</button>
	`,
})
export class ButtonComponent extends DestroyableComponent implements OnInit, OnDestroy {
	@Input() priority: `${UiColor}` = null;
	@Input() label: string;
	@Input() dataTestAttr: string;
	@Input() disabled: boolean | null = false;
	@Input() async: boolean;
	@Input() icon: string;
	@Input() iconPlacement: `${ButtonIconPlacement}` = null;
	@Input() fullWidth: boolean;
	@Input() active: boolean | null = false;

	@Input() set success(value: boolean | null) {
		this.successDefined = true;
		this.successed$.next();
	}
	@Input() set error(value: boolean | null) {
		this.errorDefined = true;
		this.errored$.next();
	}

	@Output() clicked = new EventEmitter<void>();

	successDefined: boolean;
	errorDefined: boolean;
	IconPlacement = ButtonIconPlacement;
	state$ = new BehaviorSubject<'default' | 'processing' | 'success' | 'error'>('default');
	clicked$ = new Subject<void>();
	successed$ = new Subject<void>();
	errored$ = new Subject<void>();

	icon$ = this.state$.pipe(
		map((state) => {
			switch (state) {
				case 'processing':
					return 'faSpinnerThird';
				case 'success':
					return 'faCheck';
				case 'error':
					return 'faExclamationTriangle';
				default:
					return this.icon;
			}
		})
	);

	iconSpin$ = this.state$.pipe(map((state) => state === 'processing'));

	get isDisabled() {
		if (this.disabled) {
			return true;
		}

		if (this.state$.getValue() === 'processing') {
			return true;
		}

		return false;
	}

	ngOnInit(): void {
		this.successed$
			.pipe(
				tap(() => this.state$.next('success')),
				debounceTime(debounceTimes.xxl),
				tap(() => this.state$.next('default')),
				takeUntil(this.destroy$)
			)
			.subscribe();

		this.errored$
			.pipe(
				tap(() => this.state$.next('error')),
				debounceTime(debounceTimes.xxl),
				tap(() => this.state$.next('default')),
				takeUntil(this.destroy$)
			)
			.subscribe();

		this.clicked$.pipe(takeUntil(this.destroy$)).subscribe(() => {
			this.clicked.emit();

			if (this.async && this.successDefined) {
				this.state$.next('processing');
			} else {
				this.state$.next('default');
			}
		});
	}

	ngOnDestroy(): void {
		this.state$.complete();
		this.clicked$.complete();
		this.successed$.complete();
		this.errored$.complete();
		super.ngOnDestroy();
	}

	get buttonClass(): ButtonClass {
		return {
			'button--default': this.priority === UiColor.DEFAULT,
			'button--primary': this.priority === UiColor.PRIMARY,
			'button--secondary': this.priority === UiColor.SECONDARY,
			'button--tertiary': this.priority === UiColor.TERTIARY,
			'button--info': this.priority === UiColor.INFO,
			'button--success': this.priority === UiColor.SUCCESS,
			'button--warning': this.priority === UiColor.WARNING,
			'button--danger': this.priority === UiColor.DANGER,
			'button--artificial-intelligence': this.priority === UiColor.ARTIFICIAL_INTELLIGENCE,
			'button--full-width': this.fullWidth,
			'button--active': this.active,
		};
	}
}
