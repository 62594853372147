import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';

import { NotaryService } from '../../services/notary.service';
import * as NotaryActions from './notary.actions';

@Injectable()
export class NotaryEffects {
	loadNotaries$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(NotaryActions.loadNotaries),
			switchMap(() => {
				return this.notaryService.getNotaries().pipe(
					map((notaries) => NotaryActions.loadNotariesSuccess({ notaries })),
					catchError((error) => of(NotaryActions.loadNotariesFailure({ error })))
				);
			})
		);
	});

	searchNotaries$ = createEffect(() =>
		this.actions$.pipe(
			ofType(NotaryActions.searchNotaries),
			switchMap(({ searchTerm, searchFields }) =>
				this.notaryService.searchNotaries(searchTerm, searchFields).pipe(
					map((notaries) => NotaryActions.searchNotariesSuccess({ notaries })), // Dispatch success action
					catchError((error) => of(NotaryActions.searchNotariesFailure({ error }))) // Dispatch failure action
				)
			)
		)
	);

	loadNotary$ = createEffect(() =>
		this.actions$.pipe(
			ofType(NotaryActions.loadNotary),
			switchMap(({ id }) =>
				this.notaryService.getNotary(id).pipe(
					map((notary) => NotaryActions.loadNotarySuccess({ notary })),
					catchError((error) => of(NotaryActions.loadNotaryFailure({ error })))
				)
			)
		)
	);

	constructor(
		private actions$: Actions,
		private notaryService: NotaryService
	) {}
}
