<div [class]="'box--' + (isTransparent ? 'transparent-' + color : color)">
	<oper-client-fontawesome-icon class="box__icon" [icon]="icon" [fixedWidth]="true"></oper-client-fontawesome-icon>

	<section class="box__content">
		<div *ngIf="title" class="box__content__title" [innerHTML]="title | translate: titleTranslationParams | safeHtml"></div>
		<div *ngIf="content" class="box__content__text" [innerHTML]="content | translate: contentTranslationParams | safeHtml"></div>
	</section>

	<oper-client-fontawesome-icon
		*ngIf="!disableClose"
		class="box__icon--close"
		[icon]="'faXmark'"
		[fixedWidth]="true"
		(click)="closed.emit()"
	></oper-client-fontawesome-icon>
</div>
