import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { InterestRateLockDurationConfig } from '@oper-client/shared/data-model';

export const INTEREST_RATE_LOCK_DURATION_SERVICE = new InjectionToken<IInterestRateLockDurationConfigService>(
	'INTEREST_RATE_LOCK_DURATION_SERVICE'
);

export interface IInterestRateLockDurationConfigService {
	load(configurationName?: string): Observable<InterestRateLockDurationConfig>;
}
