<form [formGroup]="form" (keyup.enter)="form.valid && enterKeyUp.emit($event)" [attr.data-test]="formName">
	<div class="containerFormField">
		@if (formConfiguration && formConfiguration?.formControl?.questions?.length === 0) {
			<oper-client-information-box
				class="alert-box"
				[title]="'ç.feature.dynamicForm.missingConfiguration'"
				[color]="'error'"
				[disableClose]="true"
			/>
		} @else {
			<div
				*ngFor="let question of formConfiguration?.formControl.questions; index as index"
				class="form-question"
				[ngClass]="question.class"
			>
				@if (isSection(question.controlType) && question.type !== 'hidden') {
					<oper-client-dynamic-form-section
						[class]="question.class"
						[title]="question.title"
						[titleTranslateParams]="question.titleTranslateParams"
					/>
				}

				@if (isHeader(question.controlType) && question.type !== 'hidden') {
					<oper-client-dynamic-form-header [title]="question.title" [subtitle]="question.subtitle" />
				}

				@if (isInformationBox(question.controlType) && question.type !== 'hidden') {
					<oper-client-information-box
						[title]="question.title"
						[content]="question.content"
						[color]="question.color"
						[icon]="question.icon"
						[disableClose]="true"
					/>
				}

				@if (isQuestion(question.controlType)) {
					<oper-client-dynamic-form-question
						class="form-question--internals"
						[formName]="formName"
						[formControlId]="question.key + '__' + formId"
						[formId]="formId"
						[form]="form"
						[question]="question"
						[numberOfQuestions]="formConfiguration.formControl.questions.length"
						(valueChange)="valueChanged()"
						(labelLinkClick)="labelLinkClick.emit()"
						(externalActionClick)="externalAction.emit($event)"
						[debounceTime]="debounceTime"
						[keydownDebounceExtension]="keydownDebounceExtension"
						[markAsTouched]="markAllAsTouched"
						[ngClass]="[question.key, question.isVerticalLayout ? 'vertical-layout' : '']"
						[attr.data-test]="question.key"
					/>
				}
			</div>
		}
	</div>
</form>
