import { ChangeDetectionStrategy, Component, input } from '@angular/core';

@Component({
	selector: 'oper-client-dynamic-form-header',
	templateUrl: './dynamic-form-header.component.html',
	styleUrl: './dynamic-form-header.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DynamicFormHeaderComponent {
	readonly title = input.required<string>();
	readonly subtitle = input<string>();
}
