import { PartialNormalizedResource, Realty, Resource, ResourceType } from '@oper-client/shared/data-model';

import { InputField, InputSelect } from '../../models/input-types.model';
import { InputBase } from '../../models/input-base.model';
import { ValidatorService } from '../../services/validator.service';
import { COMMON_REGEX_EXPRESSIONS } from '../../services/validator-constants';
import { combineLatest, Observable, of, startWith } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { filter, map } from 'rxjs/operators';
import { FormConfiguration } from '../../models/dynamic-form.model';
import { betaSelectContainsValues } from '../../utils/dynamic-form.utils';

export default function (
	formData?: Partial<Realty>,
	resources?: PartialNormalizedResource,
	multiplePurposesEnabled = false,
	defaultRealtyPurposes: number[] = []
): InputBase<any>[] {
	const purposeOptions = (resources?.[ResourceType.REALTY_PURPOSE] || []).filter(({ definition }) =>
		['buy', 'build', 'refinance', 'renovate', 'collateral', 'buyOut', 'sell'].includes(definition)
	);
	let realtyPurposeToBuy: Resource;
	let realtyPurposeToRenovate: Resource;
	let realtyPurposeToBuild: Resource;

	resources[ResourceType.REALTY_PURPOSE].forEach((resource) => {
		if (resource.definition === 'buy') {
			realtyPurposeToBuy = resource;
		}
		if (resource.definition === 'renovate') {
			realtyPurposeToRenovate = resource;
		}
		if (resource.definition === 'build') {
			realtyPurposeToBuild = resource;
		}
	});
	const livingUsageTypeId = resources?.[ResourceType.REALTY_USAGE_TYPE]?.find((item) => item.definition === 'living')?.id;
	let realtyPurpose;

	if (!multiplePurposesEnabled) {
		realtyPurpose = formData?.purposes?.[0]?.id ?? realtyPurposeToBuy.id;
	} else {
		realtyPurpose = formData?.purposes?.length ? formData?.purposes.map((p) => p.id) : defaultRealtyPurposes;
	}
	const purposeKey = multiplePurposesEnabled ? 'purposes' : 'purposes[0].id';

	return [
		new InputSelect({
			key: purposeKey,
			label: 'ç.question.mainPurpose.label',
			value: realtyPurpose,
			required: true,
			options: purposeOptions,
			class: 'span12',
			multiple: multiplePurposesEnabled,
			beta: multiplePurposesEnabled,
		}),
		new InputSelect({
			key: 'realtyType.id',
			label: 'ç.question.realtyType.label',
			value: formData?.realtyType?.id,
			required: true,
			options: resources?.[ResourceType.REALTY_TYPE] || [],
			validators: [],
			class: 'span12',
		}),
		new InputSelect({
			key: 'usageTypes[0].id',
			label: 'ç.question.usageType.label',
			value: formData?.usageTypes?.[0]?.id,
			helpText: of('ç.feature.property.usageType.helpText'),
			required: true,
			options: resources?.[ResourceType.REALTY_USAGE_TYPE] || [],
			class: 'span12',
		}),
		new InputField({
			key: 'price',
			label: 'ç.question.price.label',
			value: formData?.price,
			type: 'number',
			currency: true,
			required: true,
			class: 'span12',
			transformField: (formGroup: FormGroup, formConfiguration: FormConfiguration): Observable<InputField> => {
				const inputField = <InputField>formConfiguration.formControl.questions.find((q) => q.key === 'price');
				const formControl = <FormControl>formGroup.controls['price'];
				return formGroup.controls[purposeKey].valueChanges.pipe(
					startWith(formGroup.controls[purposeKey].value),
					filter((value) => !!value),
					map((value) => {
						if (betaSelectContainsValues(value, [realtyPurposeToBuild, realtyPurposeToRenovate])) {
							inputField.type = 'hidden';
							inputField.disabled = true;
							formControl.disable();
						} else {
							inputField.type = 'number';
							inputField.disabled = false;
							formControl.enable();
						}

						return inputField;
					})
				);
			},
		}),
		new InputField({
			key: 'venalValueBefore',
			label: 'ç.question.venalValueBeforeRenovation.label',
			value: formData?.venalValueBefore,
			type: 'hidden',
			disabled: true,
			currency: true,
			required: true,
			class: 'span12',
			transformField: (formGroup: FormGroup, formConfiguration: FormConfiguration): Observable<InputField> => {
				const inputField = <InputField>formConfiguration.formControl.questions.find((q) => q.key === 'venalValueBefore');
				const formControl = <FormControl>formGroup.controls['venalValueBefore'];
				return formGroup.controls[purposeKey].valueChanges.pipe(
					startWith(formGroup.controls[purposeKey].value),
					filter((value) => !!value),
					map((value) => {
						if (!betaSelectContainsValues(value, [realtyPurposeToRenovate])) {
							inputField.type = 'hidden';
							inputField.disabled = true;
							formControl.disable();
						} else {
							inputField.type = 'number';
							inputField.disabled = false;
							formControl.enable();
						}

						return inputField;
					})
				);
			},
		}),
		new InputField({
			key: 'priceLand',
			label: 'ç.question.priceLand.label',
			value: formData?.priceLand,
			type: 'hidden',
			disabled: true,
			currency: true,
			required: true,
			class: 'span12',
			transformField: (formGroup: FormGroup, formConfiguration: FormConfiguration): Observable<InputField> => {
				const inputField = <InputField>formConfiguration.formControl.questions.find((q) => q.key === 'priceLand');
				const formControl = <FormControl>formGroup.controls['priceLand'];
				return formGroup.controls[purposeKey].valueChanges.pipe(
					startWith(formGroup.controls[purposeKey].value),
					filter((value) => !!value),
					map((value) => {
						if (!betaSelectContainsValues(value, [realtyPurposeToBuild])) {
							inputField.type = 'hidden';
							inputField.disabled = true;
							formControl.disable();
						} else {
							inputField.type = 'number';
							inputField.disabled = false;
							formControl.enable();
						}

						return inputField;
					})
				);
			},
		}),
		new InputField({
			key: 'priceBuilding',
			label: 'ç.question.priceBuilding.label',
			value: formData?.priceBuilding,
			type: 'hidden',
			disabled: true,
			currency: true,
			required: true,
			class: 'span12',
			transformField: (formGroup: FormGroup, formConfiguration: FormConfiguration): Observable<InputField> => {
				const inputField = <InputField>formConfiguration.formControl.questions.find((q) => q.key === 'priceBuilding');
				const formControl = <FormControl>formGroup.controls['priceBuilding'];
				return formGroup.controls[purposeKey].valueChanges.pipe(
					startWith(formGroup.controls[purposeKey].value),
					filter((value) => !!value),
					map((value) => {
						if (!betaSelectContainsValues(value, [realtyPurposeToBuild])) {
							inputField.type = 'hidden';
							inputField.disabled = true;
							formControl.disable();
						} else {
							inputField.type = 'number';
							inputField.disabled = false;
							formControl.enable();
						}

						return inputField;
					})
				);
			},
		}),
		new InputField({
			key: 'venalValueAfter',
			label: 'ç.question.venalValueAfterRenovation.label',
			value: formData?.venalValueAfter,
			type: 'hidden',
			required: false,
			disabled: true,
			transformField: (formGroup: FormGroup, formConfiguration: FormConfiguration): Observable<InputField> => {
				const inputField = <InputField>formConfiguration.formControl.questions.find((q) => q.key === 'venalValueAfter');
				const formControl = <FormControl>formGroup.controls['venalValueAfter'];
				return combineLatest([
					formGroup.controls[purposeKey].valueChanges.pipe(startWith(formGroup.controls[purposeKey].value)),
					formGroup.controls['priceBuilding'].valueChanges.pipe(startWith(formGroup.controls['priceBuilding'].value)),
					formGroup.controls['priceLand'].valueChanges.pipe(startWith(formGroup.controls['priceLand'].value)),
				]).pipe(
					startWith(formGroup.controls[purposeKey].value),
					filter(([, priceBuilding, priceLand]) => !!priceBuilding && !!priceLand),
					map(([loanPurposeId, priceBuilding, priceLand]) => {
						if (betaSelectContainsValues(loanPurposeId, [realtyPurposeToBuild, realtyPurposeToRenovate])) {
							inputField.value = priceBuilding + priceLand;
							inputField.disabled = false;
							formControl.enable();
							formControl.setValue(inputField.value);
						} else {
							inputField.disabled = true;
							formControl.disable();
						}

						return inputField;
					})
				);
			},
		}),
		new InputField({
			key: 'address.zipCode',
			label: 'ç.question.zipCode.label',
			value: formData?.address?.zipCode || '',
			type: 'text',
			required: false,
			validators: [
				ValidatorService.getTrimmedPatternValidator(COMMON_REGEX_EXPRESSIONS.ONLY_NUMBERS, 'onlyNumbers'),
				Validators.maxLength(8),
			],
			updateValidityOnFormValueChanges: true,
			class: 'span12',
		}),
		new InputField({
			key: 'mainFirstResidence',
			label: 'ç.question.mainFirstResidence.label',
			value: formData?.mainFirstResidence || false,
			type: 'checkbox',
			class: 'span12',
			helpText: of('ç.feature.property.mainFirstResidence.helpText'),
			required: false,
			transformField: (formGroup: FormGroup, formConfiguration: FormConfiguration): Observable<InputField> => {
				const inputField = <InputField>formConfiguration.formControl.questions.find((q) => q.key === 'mainFirstResidence');
				const formControl = <FormControl>formGroup.controls['mainFirstResidence'];

				return formGroup.controls['usageTypes[0].id'].valueChanges.pipe(
					startWith(formGroup.controls['usageTypes[0].id'].value),
					filter((value) => !!value),
					map((value) => {
						if (value === livingUsageTypeId) {
							inputField.value = true;
							inputField.disabled = true;
						} else {
							inputField.disabled = false;
							inputField.value = false;
						}
						formControl.setValue(inputField.value);
						return inputField;
					})
				);
			},
		}),
	];
}
