import { createAction, props } from '@ngrx/store';
import {
	BaseProductDiscounts,
	BorrowerSimulationDto,
	Offer,
	Product,
	Resource,
	SelectedBaseProductDiscounts,
	Simulator,
	Step,
} from '@oper-client/shared/data-model';
import { BorrowerSimulatorFeatureConfiguration } from '../../interface/mortgage-simulator-feature.interface';
import { FormConfiguration } from '@oper-client/shared/util-dynamic-form';
import { HttpErrorResponse } from '@angular/common/http';

export const setActiveStep = createAction('[BorrowerSimulatorApplicationFlow] Set Active Step', props<{ step: Step }>());
export const updateActiveStep = createAction('[BorrowerSimulatorApplicationFlow] Update Active Step', props<{ changes: Partial<Step> }>());
export const setConfiguration = createAction(
	'[BorrowerSimulatorApplicationFlow] Set Configuration',
	props<{ configuration: BorrowerSimulatorFeatureConfiguration }>()
);
export const updateConfiguration = createAction(
	'[BorrowerSimulatorApplicationFlow] Update Configuration',
	props<{ changes: Partial<BorrowerSimulatorFeatureConfiguration> }>()
);
export const nextStep = createAction('[BorrowerSimulatorApplicationFlow] Next Step');
export const prevStep = createAction('[BorrowerSimulatorApplicationFlow] Previous Step');
export const reset = createAction('[BorrowerSimulatorApplicationFlow] Reset');
export const clearData = createAction('[BorrowerSimulatorApplicationFlow] Clear Data');
export const setData = createAction('[BorrowerSimulatorApplicationFlow] Set Data', props<{ data: Partial<BorrowerSimulationDto> }>());
export const setDataForStep = createAction(
	'[BorrowerSimulatorApplicationFlow] Set Data For Step',
	props<{ step: string; data: Partial<any> }>()
);
export const setStepFormConfiguration = createAction(
	'[BorrowerSimulatorApplicationFlow] Set Step Form Configuration',
	props<{ configuration: FormConfiguration }>()
);

export const setSelectedPurpose = createAction('[BorrowerSimulatorApplicationFlow] Set Selected Purpose', props<{ purpose: Resource }>());

export const calculateSimulation = createAction(
	'[BorrowerSimulatorApplicationFlow] Calculate Simulation',
	props<{ payload: Simulator.CalculateSimulationPayload }>()
);

export const calculateSimulationSuccess = createAction(
	'[BorrowerSimulatorApplicationFlow] Calculate Simulation Success',
	props<{ result: Simulator.CalculatedSimulationResult; payload: Partial<Simulator.CalculateSimulationPayload> }>()
);

export const calculateSimulationFailure = createAction(
	'[BorrowerSimulatorApplicationFlow] Calculate Simulation Failure',
	props<{ error: HttpErrorResponse }>()
);

export const loadOffers = createAction(
	'[BorrowerSimulatorApplicationFlow] Load Offers',
	props<{ payload: Simulator.LoadOffersPayload; queryParams?: Partial<Simulator.LoadOffersQueryParams> }>()
);

export const loadOffersSuccess = createAction(
	'[BorrowerSimulatorApplicationFlow] Load Offers Success',
	props<{ offers: Partial<Offer>[] }>()
);

export const loadOffersFailure = createAction(
	'[BorrowerSimulatorApplicationFlow] Load Offers Failure',
	props<{ error: HttpErrorResponse }>()
);

export const setSimulation = createAction(
	'[BorrowerSimulatorApplicationFlow] Set Simulation',
	props<{ simulation: Simulator.Simulation }>()
);

export const setSelectedBorrowerMode = createAction(
	'[BorrowerSimulatorApplicationFlow] Set Selected Borrower Mode',
	props<{ borrowerMode: Resource }>()
);

export const updateData = createAction('[BorrowerSimulatorApplicationFlow] Update Data', props<{ data: Partial<BorrowerSimulationDto> }>());

export const loadDiscounts = createAction(
	'[BorrowerSimulatorApplicationFlow] Load Discounts',
	props<{ payload: Partial<Simulator.LoadDiscountsPayload>; selectDefaultDiscounts: boolean }>()
);

export const loadDiscountsSuccess = createAction(
	'[BorrowerSimulatorApplicationFlow] Load Discounts Success',
	props<{ discounts: BaseProductDiscounts[] }>()
);

export const loadDiscountsFailure = createAction(
	'[BorrowerSimulatorApplicationFlow] Load Discounts Failure',
	props<{ error: HttpErrorResponse }>()
);

export const clearDiscounts = createAction('[BorrowerSimulatorApplicationFlow] Clear Discounts');

export const setSelectedDiscounts = createAction(
	'[BorrowerSimulatorApplicationFlow] Set Selected Discounts',
	props<{ discounts: SelectedBaseProductDiscounts[] }>()
);

export const loadDefaultProducts = createAction(
	'[BorrowerSimulatorApplicationFlow] Load Default Products',
	props<{ payload: Simulator.SearchProductsPayload }>()
);

export const loadDefaultProductsSuccess = createAction(
	'[BorrowerSimulatorApplicationFlow] Load Default Products Success',
	props<{ products: Product[] }>()
);

export const loadDefaultProductsFailure = createAction(
	'[BorrowerSimulatorApplicationFlow] Load Default Products Failure',
	props<{ error: HttpErrorResponse }>()
);

export const resetSimulationReport = createAction('[BorrowerSimulatorApplicationFlow] Reset Simulation Report');

export const resetSimulationActions = createAction('[BorrowerSimulatorApplicationFlow] Reset Simulation Actions');

export const updateOffer = createAction(
	'[BorrowerSimulatorApplicationFlow] Update Offer',
	props<{ payload: Partial<Simulator.UpdateOfferPayload>; queryParams?: Partial<Simulator.UpdateOfferQueryParams> }>()
);
export const updateOfferSuccess = createAction(
	'[BorrowerSimulatorApplicationFlow] Update Offer Success',
	props<{ offer: Partial<Offer> }>()
);
export const updateOfferFailure = createAction(
	'[BorrowerSimulatorApplicationFlow] Update Offer Failure',
	props<{ error: HttpErrorResponse }>()
);

export const loadSimulation = createAction('[BorrowerSimulatorApplicationFlow] Load Simulation', props<{ simulationId: number }>());
export const loadSimulationSuccess = createAction(
	'[BorrowerSimulatorApplicationFlow] Load Simulation Success',
	props<{ simulation: Simulator.Simulation }>()
);
export const loadSimulationFailure = createAction(
	'[BorrowerSimulatorApplicationFlow] Load Simulation Failure',
	props<{ error: HttpErrorResponse }>()
);

export const setReadOnlyMode = createAction('[BorrowerSimulatorApplicationFlow] Set Read Only Mode', props<{ isReadOnly: boolean }>());

export const convertSimulationToLoanRequest = createAction(
	'[BorrowerSimulatorApplicationFlow] Convert Simulation To Loan Request',
	props<{ payload: Simulator.ConvertToLoanRequestPayload }>()
);

export const convertSimulationToLoanRequestSuccess = createAction(
	'[BorrowerSimulatorApplicationFlow] Convert Simulation To Loan Request Success',
	props<{ response: Simulator.ConvertSimulationToLoanRequestResponse }>()
);

export const convertSimulationToLoanRequestFailure = createAction(
	'[BorrowerSimulatorApplicationFlow] Convert Simulation To Loan Request Failure',
	props<{ error: HttpErrorResponse }>()
);

export const createSimulation = createAction(
	'[BorrowerSimulatorApplicationFlow] Create Simulation',
	props<{ simulation: Partial<Simulator.Simulation> }>()
);

export const createSimulationSuccess = createAction(
	'[BorrowerSimulatorApplicationFlow] Create Simulation Success',
	props<{ simulation: Simulator.Simulation }>()
);

export const createSimulationFailure = createAction(
	'[BorrowerSimulatorApplicationFlow] Create Simulation Failure',
	props<{ error: HttpErrorResponse }>()
);
