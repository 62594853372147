import { HttpErrorResponse } from '@angular/common/http';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { ActionState, ActionTypes, FileCostPrediction, initialActionState, Offer, setActionState } from '@oper-client/shared/data-model';
import * as OfferActions from './offer.actions';

export const OFFER_ENTITY_KEY = 'offer';

export interface OfferState extends EntityState<Offer> {
	actions: OfferActionsState;
	currentOfferId: number | null;
	offersOpened: number[];
	predictedFileCosts?: FileCostPrediction;
}
export type OfferActionTypes = 'loadOffer' | 'loadOffers' | 'createOffer' | 'updateOffer' | 'removeOffer' | 'predictFileCosts';
export type OfferActionsState = Record<OfferActionTypes, ActionState>;

export const offerAdapter: EntityAdapter<Offer> = createEntityAdapter<Offer>();
export const initialState: OfferState = offerAdapter.getInitialState({
	currentOfferId: null,
	allowedProducts: null,
	offersOpened: [],
	actions: {
		loadOffer: initialActionState,
		loadOffers: initialActionState,
		createOffer: initialActionState,
		updateOffer: initialActionState,
		removeOffer: initialActionState,
		predictFileCosts: initialActionState,
	},
});

function setActionStates(
	actionState: OfferActionsState,
	action: OfferActionTypes,
	actionType: ActionTypes,
	error: HttpErrorResponse = null,
	ids: any = null
): OfferActionsState {
	return {
		...actionState,
		[action]: setActionState(actionState[action], actionType, error, ids),
	};
}

export const reducer = createReducer(
	initialState,

	on(OfferActions.setCurrentOfferId, (state, { offerId }) => ({
		...state,
		currentOfferId: offerId,
	})),

	on(OfferActions.setOpenedOfferIds, (state, { offerIds }) => ({
		...state,
		offersOpened: offerIds,
	})),

	on(OfferActions.loadOffer, (state, { offerId }) => {
		const ids = (<Set<number>>state.actions.loadOffer.ids || new Set<number>()).add(offerId);
		return {
			...state,
			actions: setActionStates(state.actions, 'loadOffer', ActionTypes.loading, null, ids),
		};
	}),
	on(OfferActions.loadOfferSuccess, (state, { offer }) => {
		state.actions.loadOffer.ids?.delete(offer.id);
		return offerAdapter.upsertOne(offer, {
			...state,
			actions: setActionStates(state.actions, 'loadOffer', ActionTypes.success, null, state.actions.loadOffer.ids),
		});
	}),
	on(OfferActions.loadOfferFailure, (state, { error, offerId }) => {
		state.actions.loadOffer.ids?.delete(offerId);
		return {
			...state,
			actions: setActionStates(state.actions, 'loadOffer', ActionTypes.failure, error, state.actions.loadOffer.ids),
		};
	}),

	on(OfferActions.missingDataValidation, (state, { offerId }) => {
		const ids = (<Set<number>>state.actions.loadOffer.ids || new Set<number>()).add(offerId);
		return {
			...state,
			actions: setActionStates(state.actions, 'loadOffer', ActionTypes.loading, null, ids),
		};
	}),

	on(OfferActions.missingDataValidationSuccess, (state, { offerId, missingDataValidation }) => {
		state.actions.loadOffer.ids?.delete(offerId);
		return offerAdapter.updateOne(
			{ id: offerId, changes: { missingDataValidation: missingDataValidation } },
			{
				...state,
				actions: setActionStates(state.actions, 'loadOffer', ActionTypes.success, null, state.actions.loadOffer.ids),
			}
		);
	}),
	on(OfferActions.missingDataValidationFailure, (state, { error, offerId }) => {
		state.actions.loadOffer.ids?.delete(offerId);
		return {
			...state,
			actions: setActionStates(state.actions, 'loadOffer', ActionTypes.failure, error, state.actions.loadOffer.ids),
		};
	}),

	on(OfferActions.loadOffers, (state) => ({
		...state,
		actions: setActionStates(state.actions, 'loadOffers', ActionTypes.loading),
	})),
	on(OfferActions.loadOffersSuccess, (state, { offers }) =>
		offerAdapter.setAll(offers, {
			...state,
			actions: setActionStates(state.actions, 'loadOffers', ActionTypes.success),
		})
	),
	on(OfferActions.loadOffersFailure, (state, { error }) => ({
		...state,
		actions: setActionStates(state.actions, 'loadOffers', ActionTypes.failure, error),
	})),

	on(OfferActions.createOffer, (state) => ({
		...state,
		actions: setActionStates(state.actions, 'createOffer', ActionTypes.loading),
	})),
	on(OfferActions.createOfferSuccess, (state, { offer }) =>
		offerAdapter.addOne(offer, {
			...state,
			actions: setActionStates(state.actions, 'createOffer', ActionTypes.success),
			currentOfferId: offer.id,
		})
	),
	on(OfferActions.createOfferFailure, (state, { error }) => ({
		...state,
		actions: setActionStates(state.actions, 'createOffer', ActionTypes.failure, error),
	})),

	on(OfferActions.updateOffer, (state, { offer }) => ({
		...state,
		actions: setActionStates(state.actions, 'updateOffer', ActionTypes.loading, null, { offerId: offer?.id }),
	})),
	on(OfferActions.updateOfferSuccess, (state, { offer }) =>
		offerAdapter.updateOne(offer, {
			...state,
			actions: setActionStates(state.actions, 'updateOffer', ActionTypes.success, null, { offerId: offer?.id }),
		})
	),
	on(OfferActions.updateOfferFailure, (state, { error, offer }) => ({
		...state,
		actions: setActionStates(state.actions, 'updateOffer', ActionTypes.failure, error, { offerId: offer?.id }),
	})),

	on(OfferActions.predictFileCosts, (state) => ({
		...state,
		actions: setActionStates(state.actions, 'predictFileCosts', ActionTypes.loading),
	})),
	on(OfferActions.predictFileCostsSuccess, (state, { fileCosts }) => ({
		...state,
		predictedFileCosts: fileCosts,
		actions: setActionStates(state.actions, 'predictFileCosts', ActionTypes.success),
	})),
	on(OfferActions.predictFileCostsFailure, (state, { error }) => ({
		...state,
		actions: setActionStates(state.actions, 'predictFileCosts', ActionTypes.failure, error),
	})),
	on(OfferActions.clearOffers, (state) => offerAdapter.removeAll({ ...state, currentOfferId: null, predictedFileCosts: null })),

	on(OfferActions.removeOffer, (state) => ({
		...state,
		actions: setActionStates(state.actions, 'removeOffer', ActionTypes.loading),
	})),
	on(OfferActions.removeOfferSuccess, (state, { offerId }) =>
		offerAdapter.removeOne(offerId, {
			...state,
			actions: setActionStates(state.actions, 'removeOffer', ActionTypes.success),
			currentOfferId: 0,
		})
	),
	on(OfferActions.removeOfferFailure, (state, { error }) => ({
		...state,
		actions: setActionStates(state.actions, 'removeOffer', ActionTypes.failure, error),
	}))
);
