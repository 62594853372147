import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
	BorrowerModes,
	LoanRequest,
	LoanRequestComment,
	LoanRequestCommunicationHistory,
	LoanRequestDecision,
	LoanRequestDecisionStatistics,
	LoanRequestHistory,
	LoanRequestStatusHistory,
	PreapprovalResponse,
	Resource,
} from '@oper-client/shared/data-model';
import { InjectionToken } from '@angular/core';

export const LOAN_REQUEST_SERVICE = new InjectionToken<ILoanRequestService>('PRODUCT_SERVICE');

export interface ILoanRequestService {
	getLoanRequests(params?: HttpParams, shouldFilterByType?: boolean): Observable<{ results: LoanRequest[]; count: number }>;

	getLoanRequestsTotalAmount(params?: HttpParams): Observable<number>;

	getLoanRequestStatuses(loanRequestId: number, params?: HttpParams): Observable<Resource[]>;

	resetLoanRequestStatus(loanRequestId: number, body: Partial<LoanRequest>): Observable<LoanRequest>;

	updateLoanRequestStatus(loanRequestId: number, body: Partial<LoanRequest>): Observable<LoanRequest>;

	createLoanRequest(data: Partial<LoanRequest>): Observable<LoanRequest>;

	getLoanRequest(loanRequestId: number, queryParams?: { [key: string]: string }): Observable<Partial<LoanRequest>>;

	updateLoanRequest(loanRequestId: number, body: Partial<LoanRequest>): Observable<LoanRequest>;

	assignAnalystToLoanRequest(loanRequestId: number, analystId: number | null): Observable<LoanRequest>;

	assignBrokerToLoanRequest(loanRequestId: number, brokerId: number | null): Observable<LoanRequest>;

	updateAcquisitionSource(loanRequestId: number, acquisitionSourceId: number | null): Observable<LoanRequest>;

	createDecision(loanRequestId: number, body: LoanRequestDecision): Observable<LoanRequestDecision>;

	getDecisions(loanRequestId: number): Observable<LoanRequestDecision[]>;

	getDecisionsStatistics(loanRequestId: number): Observable<LoanRequestDecisionStatistics>;

	createComment(loanRequestId: number, body: LoanRequestComment): Observable<LoanRequestComment>;

	getComments(loanRequestId: number): Observable<LoanRequestComment[]>;

	getHistories(
		loanRequestId: number,
		queryParams: any
	): Observable<(LoanRequestHistory | LoanRequestStatusHistory | LoanRequestCommunicationHistory)[]>;

	getPreapproval(loanRequestId: number, borrowerMode: BorrowerModes): Observable<PreapprovalResponse>;

	loadPreapproval(loanRequestId: number): Observable<PreapprovalResponse>;

	toggleLoanRequestCancellation(loanRequestId, body?: any): Observable<LoanRequest>;
}
