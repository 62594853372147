import { DynamicFormCard, HeaderSection, InputField, InputSelect, Section } from '../models/input-types.model';
import { InputBase } from '../models/input-base.model';
import {
	CreditLiability,
	creditLiabilityDefinitions,
	CreditProvider,
	CreditTakeoverEnum,
	Liability,
	LoanApplicationDto,
	PartialNormalizedResource,
	Periodicity,
} from '@oper-client/shared/data-model';
import { FormConfiguration } from '../models/dynamic-form.model';
import { convertCreditProviderToOptions, SelectOption } from '@oper-client/shared/util-data-model-transform';
import { ValidatorService } from '../services/validator.service';
import { DATE_RESTRICTION } from '../services/validator-constants';

export default function (
	formData?: LoanApplicationDto,
	resources?: PartialNormalizedResource,
	creditProviders?: CreditProvider[]
): InputBase<any>[] {
	const questions: InputBase<any>[] = [];
	const creditLiabilities = getFilteredCreditLiabilities(formData?.mainBorrowerLiabilities);
	if (creditLiabilities.length > 0) {
		questions.push(
			new Section({
				class: 'span12',
			}),
			new HeaderSection({
				title: 'ç.feature.selfService.loanApplication.additionalRefinance.title',
				subtitle: 'ç.feature.selfService.loanApplication.additionalRefinance.description',
			})
		);

		const creditProviderOptions = convertCreditProviderToOptions(creditProviders ?? []);
		creditLiabilities.forEach((liability, index) => {
			const liabilityFormConfiguration = new FormConfiguration();
			liabilityFormConfiguration.setName(`mainBorrowerLiabilitiesForm[${index}]`);
			const liabilityType = liability.liabilityType.definition;
			liabilityFormConfiguration.formControl.questions = getCreditLiabilityFormQuestions(liability, creditProviderOptions);
			questions.push(
				new DynamicFormCard({
					title: `ç.resource.liability-type.${liabilityType}`,
					key: `mainBorrowerLiabilities[${index}]`,
					formConfiguration: liabilityFormConfiguration,
					showDeleteButton: false,
				})
			);
		});
	}

	return questions;
}

function getCreditLiabilityFormQuestions(
	formData: Partial<CreditLiability>,
	creditProviders: SelectOption[],
	trancheNumber = 0
): InputBase<any>[] {
	const questions: InputBase<any>[] = [];
	const minStartDate: number = ValidatorService.getDateByYearOffset(50).getTime();
	const currentDate: number = ValidatorService.getDateByYearOffset(0).getTime();
	if (trancheNumber > 0) {
		questions.push(
			new Section({
				class: 'span12',
				title: `ç.misc.trancheNumber`,
				titleTranslateParams: { number: trancheNumber },
			})
		);
	}

	questions.push(
		...[
			new InputField({
				key: 'id',
				value: formData?.id,
				type: 'hidden',
				required: false,
			}),
			new InputField({
				key: 'liabilityType',
				value: formData?.liabilityType,
				type: 'hidden',
				required: false,
			}),
			new InputField({
				key: 'takeover',
				value: formData?.takeover,
				type: 'hidden',
				required: false,
			}),
			new InputField({
				key: 'initialAmount',
				label: 'ç.feature.mortgageSimulator.originalLoanAmount',
				type: 'number',
				value: formData?.initialAmount,
				required: true,
				currency: true,
				readonly: formData?.initialAmount > 0,
				class: 'span12',
				hideRequiredAsterisk: true,
			}),
			new InputSelect({
				key: 'creditProvider.id',
				label: 'ç.question.financialInstitution.label',
				value: formData?.creditProvider?.id,
				required: true,
				hideRequiredAsterisk: true,
				options: creditProviders,
			}),
			new InputField({
				key: 'contractNumber',
				label: 'ç.question.referenceNumber.label',
				type: 'text',
				value: formData?.contractNumber,
				required: true,
				hideRequiredAsterisk: true,
			}),
			new InputField({
				key: 'amount',
				label: 'ç.question.monthlyInstalmentAmount.label',
				value: formData.amount !== formData.balance ? formData.amount : formData?.monthlyAmount,
				required: true,
				hideRequiredAsterisk: true,
				type: 'number',
				currency: true,
			}),
			new InputField({
				key: 'periodicity',
				value: formData?.periodicity ?? { definition: Periodicity.MONTHLY },
				type: 'hidden',
				required: false,
			}),
			new InputField({
				key: 'balance',
				value: formData?.balance,
				type: 'hidden',
				required: false,
			}),
			new InputField({
				key: 'startDate',
				label: 'ç.question.startDate.label',
				value: formData?.startDate,
				required: true,
				hideRequiredAsterisk: true,
				type: 'date',
				validators: [
					ValidatorService.getDateRestrictionValidatior('past', currentDate, DATE_RESTRICTION.FUTURE),
					ValidatorService.getDateRestrictionValidatior('notEarlierThanYear', minStartDate, DATE_RESTRICTION.PAST),
				],
				min: minStartDate,
				max: currentDate,
			}),
			new InputField({
				key: 'endDate',
				label: 'ç.question.endDate.label',
				value: formData?.endDate,
				required: true,
				hideRequiredAsterisk: true,
				type: 'date',
				validators: [ValidatorService.getDateRestrictionValidatior('future', currentDate, DATE_RESTRICTION.PAST)],
				min: currentDate,
			}),
		]
	);

	return questions;
}

function getFilteredCreditLiabilities(liabilities: Partial<Liability>[]): CreditLiability[] {
	return (
		(liabilities?.filter(
			(liability) =>
				creditLiabilityDefinitions.includes(liability?.liabilityType?.definition) &&
				(liability as CreditLiability)?.takeover?.definition === CreditTakeoverEnum.YES
		) as CreditLiability[]) ?? []
	)?.sort((a, b) => (a?.liabilityType?.order > b?.liabilityType?.order ? 1 : -1));
}
