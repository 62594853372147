import { RealtyPurposeEnum, Resource } from '@oper-client/shared/data-model';

export function compareProjectPurposeFormFields(previous: {loanPurposes: Resource[]}, current: {loanPurposes: Resource[]}): boolean {
	return compareResources(previous.loanPurposes ?? [], current.loanPurposes ?? []);
}

export function compareResources(previous: Resource[], current: Resource[]): boolean {
	return previous.length === current.length && previous.every((resource, index) => resource.id === current[index].id);
}

export function getRealtyPurposeFromLRPurpose(
	loanPurposes: Resource[],
	loanAndRealtyPurposeMap: Record<string, RealtyPurposeEnum[]>,
	realtyPurposeResource: Resource[]
): number[] {
	const purposesToSet = new Set<number>();
	
	if (!loanPurposes?.length) {
		return loanAndRealtyPurposeMap.default
			.map(definition => realtyPurposeResource.find(r => r.definition === definition)?.id)
			.filter(Boolean);
	}
	loanPurposes.forEach((loanPurpose) => {
		const realtyPurposes = loanAndRealtyPurposeMap[loanPurpose?.definition];
		if (realtyPurposes) {
			realtyPurposes.forEach((purpose) => purposesToSet.add(realtyPurposeResource.find(r => r.definition === purpose)?.id));
		}
	});

	return Array.from(purposesToSet);
}

