export enum ProductTypeEnum {
	BRIDGE_LOAN = 'bridgeLoan',
	MORTGAGE_LOAN = 'mortgageLoan',
	CASH_LOAN = 'cashLoan',
	FREE_PURPOSE_LOAN = 'freePurposeLoan',
	CONSUMER_FRIENDLY_LOAN = 'consumerFriendlyLoan',
	VILLAGE_CSOK_CASH_LOAN = 'villageCsokCashLoan',
	VILLAGE_CSOK_MORTGAGE_LOAN = 'villageCsokMortgageLoan',
	CSOK_MORTGAGE_LOAN = 'csokMortgageLoan',
	BABY_LOAN = 'babyLoan',
}
