import {
	ChangeDetectionStrategy,
	Component,
	computed,
	DestroyRef,
	inject,
	input,
	OnInit,
	output,
} from '@angular/core';
import { Subject, tap } from 'rxjs';
import {
	BrokerageFormEnum,
	DynamicFormConfigurationService,
	DynamicFormModule,
	FormGroupWithWarning,
} from '@oper-client/shared/util-dynamic-form';
import { PartialNormalizedResource, Resource } from '@oper-client/shared/data-model';
import { LOAN_PURPOSE_FEATURE_CONFIGURATION } from '../../tokens/loan-purpose-feature.tokens';
import { distinctUntilChanged, filter, map, skip } from 'rxjs/operators';
import { UtilService } from '@oper-client/shared/util-formatting';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { compareProjectPurposeFormFields } from '../../utils/loan-purpose.utils';
import { TranslateModule } from '@ngx-translate/core';

@Component({
	selector: 'oper-client-loan-purpose',
	templateUrl: './loan-purpose.component.html',
	styleUrls: ['./loan-purpose.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [DynamicFormModule, TranslateModule],
})
export class LoanPurposeComponent implements OnInit {
	$loanPurposes = input.required<Resource[]>();
	$readonlyMode = input<boolean>(false);
	$resources = input.required<PartialNormalizedResource>();
	$title = input<string>();
	$skipChanges = input<number>(0);
	loanPurposeChanged = output<{ loanPurposes: Resource[] }>();

	readonly $loanPurposeFormConfiguration = computed(
		() =>
			this.dynamicFormConfigurationService.getForm(
				BrokerageFormEnum.ADD_LOAN_PURPOSE,
				this.$loanPurposes()?.length ? this.$loanPurposes() : this.purposeConfig.presets(this.$resources())?.loanPurposes,
				this.$resources(),
				undefined,
				this.$readonlyMode(),
				this.purposeConfig.loanPurposes ?? [],
				this.purposeConfig.loanPurposeCombinations ?? {}
			).value
	);
	readonly loanPurposeForm$ = new Subject<FormGroupWithWarning>();

	private dynamicFormConfigurationService = inject(DynamicFormConfigurationService);
	private purposeConfig = inject(LOAN_PURPOSE_FEATURE_CONFIGURATION);
	private utilService = inject(UtilService);
	private destroyRef = inject(DestroyRef);

	ngOnInit() {
		this.loanPurposeForm$
			.pipe(
				filter((form) => !!form && form.valid),
				map((form) => this.utilService.erectObject(form.value)),
				map((formValue) => ({
					loanPurposes: (formValue.loanPurposes ?? []).filter((purpose) => !!purpose.id).map((el, index) => ({ ...el, isMain: index === 0 }))
				})),
				distinctUntilChanged((previous, current) => compareProjectPurposeFormFields(previous, current)),
				skip(this.$skipChanges()),
				tap((value) => this.loanPurposeChanged.emit(value)),
				takeUntilDestroyed(this.destroyRef)
			)
			.subscribe();
	}
}
