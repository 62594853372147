import { HttpErrorResponse } from '@angular/common/http';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import { ActionState, ActionTypes, initialActionState, setActionState, ThirdParty } from '@oper-client/shared/data-model';
import * as NotaryActions from './notary.actions';

export const NOTARY_FEATURE_KEY = 'notaries';

export type NotaryActionTypes = 'loadNotaries' | 'searchNotaries' | 'loadNotary';

export type NotaryActionsState = Record<NotaryActionTypes, ActionState>;

export interface NotaryState extends EntityState<ThirdParty> {
	actions: NotaryActionsState;
	selectedNotary: ThirdParty | null;
}

export interface NotaryPartialState {
	readonly [NOTARY_FEATURE_KEY]: NotaryState;
}

export const adapter: EntityAdapter<ThirdParty> = createEntityAdapter<ThirdParty>();

export const initialState: NotaryState = adapter.getInitialState({
	actions: {
		loadNotaries: initialActionState,
		searchNotaries: initialActionState,
		loadNotary: initialActionState,
	},
	selectedNotary: null,
});

function setActionStates(
	actionState: NotaryActionsState,
	action: NotaryActionTypes,
	actionType: ActionTypes,
	error: HttpErrorResponse = null
): NotaryActionsState {
	return {
		...actionState,
		[action]: setActionState(actionState[action], actionType, error),
	};
}

const notaryReducer = createReducer(
	initialState,
	// Load Notaries
	on(NotaryActions.loadNotaries, (state) => ({
		...state,
		actions: setActionStates(state.actions, 'loadNotaries', ActionTypes.loading),
	})),
	on(NotaryActions.loadNotariesSuccess, (state, { notaries }) =>
		adapter.setAll(notaries, {
			...state,
			actions: setActionStates(state.actions, 'loadNotaries', ActionTypes.success),
		})
	),
	on(NotaryActions.loadNotariesFailure, (state, { error }) => ({
		...state,
		actions: setActionStates(state.actions, 'loadNotaries', ActionTypes.failure, error),
	})),

	// search Notaries
	on(NotaryActions.searchNotaries, (state) => ({
		...state,
		actions: {
			...state.actions,
			searchNotaries: setActionState(state.actions.searchNotaries, ActionTypes.loading),
		},
	})),
	on(NotaryActions.searchNotariesSuccess, (state, { notaries }) => adapter.setAll(notaries, { ...state })),
	on(NotaryActions.searchNotariesFailure, (state, { error }) => ({
		...state,
		error: error,
	})),

	// load selected notary (id)
	on(NotaryActions.loadNotary, (state) => ({
		...state,
		actions: setActionStates(state.actions, 'loadNotary', ActionTypes.loading),
	})),
	on(NotaryActions.loadNotarySuccess, (state, { notary }) => ({
		...state,
		selectedNotary: notary,
		actions: setActionStates(state.actions, 'loadNotary', ActionTypes.success),
	})),
	on(NotaryActions.loadNotaryFailure, (state, { error }) => ({
		...state,
		actions: setActionStates(state.actions, 'loadNotary', ActionTypes.failure, error),
	}))
);

export function reducer(state: NotaryState | undefined, action: Action) {
	return notaryReducer(state, action);
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
