import { InputBase } from '../models/input-base.model';
import { LoanApplicationDto } from '@oper-client/shared/data-model';
import { InputField } from '../models/input-types.model';
import { Validators } from '@angular/forms';

export default function (formData?: LoanApplicationDto): InputBase<any>[] {
	return [
		new InputField({
			key: 'comment',
			label: 'ç.feature.selfService.loanApplication.submitApplication.comment.label',
			placeholder: 'ç.feature.selfService.loanApplication.submitApplication.comment.placeholder',
			value: formData?.comment,
			type: 'text',
			multiline: true,
			class: 'span12',
			required: true,
			hideRequiredAsterisk: true,
			validators: [Validators.maxLength(5000)],
		}),
	];
}
