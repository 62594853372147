import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
	BORROWER_SIMULATOR_APPLICATION_FLOW_KEY,
	BorrowerSimulatorApplicationFlowState,
} from './borrower-simulator-application-flow.reducer';

export const selectState = createFeatureSelector<BorrowerSimulatorApplicationFlowState>(BORROWER_SIMULATOR_APPLICATION_FLOW_KEY);
export const getConfiguration = createSelector(selectState, (state) => state.configuration);
export const getActiveStep = createSelector(selectState, (state) => state.activeStep);
export const getData = createSelector(selectState, (state) => state.data);
export const getDataPoints = createSelector(selectState, (state) => state.dataPoints);
export const getCalculateSimulationActionState = createSelector(selectState, (state) => state.actions.calculateSimulation);
export const getSteps = createSelector(getConfiguration, (configuration) => configuration?.steps);
export const getSelectedPurpose = createSelector(selectState, (state) => state.selectedPurpose);
export const getSimulation = createSelector(selectState, (state) => state.simulation);
export const getOffers = createSelector(selectState, (state) => state.offers);
export const getNumberOfBorrowers = createSelector(selectState, (state) => state.numberOfBorrowers);
export const getProducts = createSelector(selectState, (state) => state.products);
export const getDiscounts = createSelector(selectState, (state) => state.discounts);
export const getLoadDiscountsActionState = createSelector(selectState, (state) => state.actions.loadDiscounts);
export const getLoadDefaultProductsActionState = createSelector(selectState, (state) => state.actions.loadDefaultProducts);
export const getLoadOffersActionState = createSelector(selectState, (state) => state.actions.loadOffers);
export const getUpdateOfferActionState = createSelector(selectState, (state) => state.actions.updateOffer);
export const getLoadSimulationActionState = createSelector(selectState, (state) => state.actions.loadSimulation);
export const isReadOnlyMode = createSelector(selectState, (state) => state.isReadOnlyMode);
export const getConvertToLoanRequestActionState = createSelector(selectState, (state) => state.actions.convertSimulationToLoanRequest);
export const getCreateSimulationActionState = createSelector(selectState, (state) => state.actions.createSimulation);
export const getConvertToLoanRequestResponse = createSelector(selectState, (state) => state.convertToLoanRequestResponse);
