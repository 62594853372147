import { InjectionToken } from '@angular/core';
import { Configuration, CustomersEnum, getCustomer } from '@oper-client/shared/configuration';
import huConfiguration from '../config/hu.configuration';
import defaultConfiguration from '../config/default.configuration';
import { mergeObjects } from '@oper-client/shared/util-object';
import { PurposeConfiguration } from '../models/purpose-config';

export const LOAN_PURPOSE_FEATURE_CONFIGURATION = new InjectionToken<PurposeConfiguration>('LoanPurposeFeatureConfiguration');

export function loanPurposeConfigurationFactory(customerConfig: Configuration): PurposeConfiguration {
	const featureKey = 'loanPurpose';
	if (!customerConfig?.applicationInsights) {
		throw new Error('Customer configuration was not provided');
	}

	const featureConfiguration = customerConfig.featureConfigurations[featureKey] as PurposeConfiguration;
	const customer = getCustomer(customerConfig.applicationInsights) ?? CustomersEnum.BELGIAN;
	const defaultConfiguration = getDefaultCustomerConfiguration(customer as CustomersEnum);
	if (featureConfiguration) {
		// merge default configuration with feature configuration
		return mergeObjects(defaultConfiguration, featureConfiguration);
	} else {
		console.warn(
			`featureConfigurations.${featureKey} was not found in customer.json! Default '${customer}' loa purpose configuration will be used instead.`
		);

		return defaultConfiguration;
	}
}

/**
 * @param customer - The customer for which the configuration should be returned.
 * @returns - Loan purpose configuration for the given customer.
 */
export function getDefaultCustomerConfiguration(customer: CustomersEnum): PurposeConfiguration {
	switch (customer) {
		case CustomersEnum.FINPORTA:
			return huConfiguration;
		default:
			return defaultConfiguration;
	}
}
