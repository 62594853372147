import { createSelector } from '@ngrx/store';

import { getThirdPartyState } from '../third-party.selectors';
import { NotaryState, NotaryPartialState, NotaryActionTypes, NOTARY_FEATURE_KEY, selectAll } from './notary.reducer';

export const getNotaryState = createSelector(getThirdPartyState, (state: NotaryPartialState) => state[NOTARY_FEATURE_KEY]);
export const getNotaries = createSelector(getNotaryState, (state: NotaryState) => selectAll(state));
export const getSearchResults = createSelector(getNotaryState, (state: NotaryState) => state.actions.searchNotaries);
export const getSelectedNotary = createSelector(getNotaryState, (state: NotaryState) => state.selectedNotary);

export const getActionStates = createSelector(getNotaryState, (state) => state.actions);
export const getActionState = (actionType: NotaryActionTypes) => createSelector(getActionStates, (state) => state[actionType]);
