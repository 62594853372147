import { InputField, InputSelect } from '../models/input-types.model';
import { InputBase } from '../models/input-base.model';
import { Guarantee, PartialNormalizedResource, ResourceType } from '@oper-client/shared/data-model';

export default function (formData?: Guarantee, resources?: PartialNormalizedResource): InputBase<any>[] {
	return [
		new InputSelect({
			key: 'guaranteeType.id',
			label: 'ç.question.guarantee.type',
			value: formData?.guaranteeType?.id,
			options: resources[ResourceType.GUARANTEE_TYPE],
			required: true,
			class: 'span12',
		}),
		new InputField({
			key: 'amount',
			label: 'ç.question.guarantee.amount',
			type: 'number',
			class: 'span12',
			required: true,
			currency: true,
			value: formData?.amount,
			min: 1,
		}),
	];
}
