import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { LoadValidationData, Resource, ValidationResult } from '@oper-client/shared/data-model';
import { AiValidationConfig } from '@oper-client/shared/configuration';

export const AI_VALIDATION_SERVICE = new InjectionToken<IAiValidationServiceService>('AI_VALIDATION_SERVICE');

export interface IAiValidationServiceService {
	getConfig(): Observable<AiValidationConfig>;

	triggerValidation(
		loanRequestId: number,
		proofId: number,
		docId: number,
		verificationTypes?: Resource
	): Observable<{ id: string; status: string }>;

	getValidationResult(validationConfig: LoadValidationData): Observable<ValidationResult>;
}
