import { InputField } from '../models/input-types.model';
import { InputBase } from '../models/input-base.model';
import { FinancialPlan, FinancialPlanItem, PartialNormalizedResource } from '@oper-client/shared/data-model';

export default function (formData?: FinancialPlan, resources?: PartialNormalizedResource, isReadonly = false): InputBase<any>[] {
	const items: FinancialPlanItem[] = [];

	formData?.financialPlanOwnFundItems
		?.filter((item) => item.financialPlanOwnFundField.isOverridable && item.financialPlanOwnFundField.isMain)
		.map((item) => ({ ...item, field: { ...item.financialPlanOwnFundField, type: item.financialPlanOwnFundField.ownFundType } }))
		.forEach((item) => items.push(item));

	formData?.financialPlanLoanAmountItems
		?.filter((item) => item.financialPlanLoanAmountField.isOverridable && item.financialPlanLoanAmountField.isMain)
		.map((item) => ({
			...item,
			field: { ...item.financialPlanLoanAmountField, type: item.financialPlanLoanAmountField.loanAmountType },
		}))
		.forEach((item) => items.push(item));

	return items.map((item) => getFinancialPlanInputField(item, isReadonly)).sort((a, b) => a.order - b.order);
}

function getFinancialPlanInputField(item: FinancialPlanItem, isReadonly = false): InputField {
	const defaultSettings: Partial<InputField> = {
		key: item.field.type.definition,
		label: `ç.feature.mortgageSimulator.report.overview.${item.field.type.definition}.label`,
		value: item.amount ?? 0,
		type: 'number',
		disabled: isReadonly,
		currency: true,
		required: false,
		order: item.field.isMain && item.field.isOverridable ? 1 : item.field.type.order,
	};

	return new InputField({
		...defaultSettings,
	});
}
