import { Injectable } from '@angular/core';
import { Action, select, Store } from '@ngrx/store';

import { ThirdPartyPartialState } from '../third-party.reducer';
import * as NotarySelectors from './notary.selectors';

@Injectable()
export class NotaryFacade {
	notaries$ = this.store.pipe(select(NotarySelectors.getNotaries));
	filteredNotaries$ = this.store.pipe(select(NotarySelectors.getSearchResults));
	selectedNotary$ = this.store.pipe(select(NotarySelectors.getSelectedNotary));
	constructor(private store: Store<ThirdPartyPartialState>) {}

	dispatch(action: Action) {
		this.store.dispatch(action);
	}
}
