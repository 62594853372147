export enum UiColor {
	DEFAULT = 'default',

	// Priority colors
	PRIMARY = 'primary',
	SECONDARY = 'secondary',
	TERTIARY = 'tertiary',

	// Semantiv colors
	INFO = 'info',
	SUCCESS = 'success',
	WARNING = 'warning',
	DANGER = 'danger',
	// For error messages without background color
	ERROR = 'error',

	// Special colors
	ARTIFICIAL_INTELLIGENCE = 'artificial-intelligence',
}
