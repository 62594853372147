import { Inject, Injectable } from '@angular/core';
import * as BorrowerSimulatorApplicationFlowActions from './borrower-simulator-application-flow.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap, takeUntil } from 'rxjs';
import {
	BORROWER_SIMULATOR_NUMBER_OF_BORROWERS_STEP,
	BORROWER_SIMULATOR_PROJECT_PURPOSE_STEP,
} from './borrower-simulator-application-flow.reducer';
import { ADVISOR_SIMULATOR_SERVICE, IAdvisorSimulatorService } from '@oper-client/shared/data-access';
import { cancelPendingHttpRequests } from '@oper-client/shared/app/data-access-app';
import { LanguageService } from '@oper-client/shared/util-language';

@Injectable()
export class BorrowerSimulatorApplicationFlowEffects {
	readonly setSelectedPurpose$ = createEffect(() =>
		this.actions$.pipe(
			ofType(BorrowerSimulatorApplicationFlowActions.setSelectedPurpose),
			map(({ purpose }) =>
				BorrowerSimulatorApplicationFlowActions.setDataForStep({
					step: BORROWER_SIMULATOR_PROJECT_PURPOSE_STEP,
					data: { loanPurpose: purpose },
				})
			)
		)
	);

	readonly setNumberOfBorrowers$ = createEffect(() =>
		this.actions$.pipe(
			ofType(BorrowerSimulatorApplicationFlowActions.setSelectedBorrowerMode),
			map(({ borrowerMode }) =>
				BorrowerSimulatorApplicationFlowActions.setDataForStep({
					step: BORROWER_SIMULATOR_NUMBER_OF_BORROWERS_STEP,
					data: { numberOfBorrowers: borrowerMode },
				})
			)
		)
	);

	readonly calculateSimulation = createEffect(() =>
		this.actions$.pipe(
			ofType(BorrowerSimulatorApplicationFlowActions.calculateSimulation),
			switchMap(({ payload }) =>
				this.simulatorService.calculateSimulation(payload).pipe(
					map((result) => BorrowerSimulatorApplicationFlowActions.calculateSimulationSuccess({ result, payload })),
					takeUntil(this.actions$.pipe(ofType(cancelPendingHttpRequests))),
					catchError((error) => of(BorrowerSimulatorApplicationFlowActions.calculateSimulationFailure({ error })))
				)
			)
		)
	);

	readonly loadOffers$ = createEffect(() =>
		this.actions$.pipe(
			ofType(BorrowerSimulatorApplicationFlowActions.loadOffers),
			switchMap(({ payload, queryParams }) =>
				this.simulatorService
					.loadOffers(payload, {
						...queryParams,
						isBorrowerAcceptanceRules: true,
						currentLanguage: this.languageService.getCurrentLanguage(),
					})
					.pipe(
						map((offers) => BorrowerSimulatorApplicationFlowActions.loadOffersSuccess({ offers })),
						takeUntil(this.actions$.pipe(ofType(cancelPendingHttpRequests))),
						catchError((error) => of(BorrowerSimulatorApplicationFlowActions.loadOffersFailure({ error })))
					)
			)
		)
	);

	readonly loadDiscounts$ = createEffect(() =>
		this.actions$.pipe(
			ofType(BorrowerSimulatorApplicationFlowActions.loadDiscounts),
			switchMap(({ payload, selectDefaultDiscounts }) =>
				this.simulatorService.loadDiscounts(payload, selectDefaultDiscounts).pipe(
					map((discounts) => BorrowerSimulatorApplicationFlowActions.loadDiscountsSuccess({ discounts })),
					takeUntil(this.actions$.pipe(ofType(cancelPendingHttpRequests))),
					catchError((error) => of(BorrowerSimulatorApplicationFlowActions.loadDiscountsFailure({ error })))
				)
			)
		)
	);

	readonly loadDefaultProducts$ = createEffect(() =>
		this.actions$.pipe(
			ofType(BorrowerSimulatorApplicationFlowActions.loadDefaultProducts),
			switchMap(({ payload }) =>
				this.simulatorService.loadDefaultProducts(payload).pipe(
					map((products) => BorrowerSimulatorApplicationFlowActions.loadDefaultProductsSuccess({ products })),
					takeUntil(this.actions$.pipe(ofType(cancelPendingHttpRequests))),
					catchError((error) => of(BorrowerSimulatorApplicationFlowActions.loadDefaultProductsFailure({ error })))
				)
			)
		)
	);

	readonly updateOffer$ = createEffect(() =>
		this.actions$.pipe(
			ofType(BorrowerSimulatorApplicationFlowActions.updateOffer),
			switchMap(({ payload, queryParams }) =>
				this.simulatorService.updateOffer(payload, { ...queryParams, isBorrowerAcceptanceRules: true }).pipe(
					map((offer) => BorrowerSimulatorApplicationFlowActions.updateOfferSuccess({ offer })),
					takeUntil(this.actions$.pipe(ofType(cancelPendingHttpRequests))),
					catchError((error) => of(BorrowerSimulatorApplicationFlowActions.updateOfferFailure({ error })))
				)
			)
		)
	);

	readonly loadSimulation$ = createEffect(() =>
		this.actions$.pipe(
			ofType(BorrowerSimulatorApplicationFlowActions.loadSimulation),
			switchMap(({ simulationId }) =>
				this.simulatorService.loadSimulation(simulationId).pipe(
					map((simulation) => BorrowerSimulatorApplicationFlowActions.loadSimulationSuccess({ simulation })),
					takeUntil(this.actions$.pipe(ofType(cancelPendingHttpRequests))),
					catchError((error) => of(BorrowerSimulatorApplicationFlowActions.loadSimulationFailure({ error })))
				)
			)
		)
	);

	readonly convertSimulationToLoanRequest$ = createEffect(() =>
		this.actions$.pipe(
			ofType(BorrowerSimulatorApplicationFlowActions.convertSimulationToLoanRequest),
			switchMap(({ payload }) =>
				this.simulatorService.convertSimulationToLoanRequest(payload).pipe(
					map((response) => BorrowerSimulatorApplicationFlowActions.convertSimulationToLoanRequestSuccess({ response })),
					takeUntil(this.actions$.pipe(ofType(cancelPendingHttpRequests))),
					catchError((error) => of(BorrowerSimulatorApplicationFlowActions.convertSimulationToLoanRequestFailure({ error })))
				)
			)
		)
	);

	readonly createSimulation$ = createEffect(() =>
		this.actions$.pipe(
			ofType(BorrowerSimulatorApplicationFlowActions.createSimulation),
			switchMap(({ simulation }) =>
				this.simulatorService.createSimulation(simulation).pipe(
					map((simulation) => BorrowerSimulatorApplicationFlowActions.createSimulationSuccess({ simulation })),
					takeUntil(this.actions$.pipe(ofType(cancelPendingHttpRequests))),
					catchError((error) => of(BorrowerSimulatorApplicationFlowActions.createSimulationFailure({ error })))
				)
			)
		)
	);

	constructor(
		private actions$: Actions,
		private readonly languageService: LanguageService,
		@Inject(ADVISOR_SIMULATOR_SERVICE) private simulatorService: IAdvisorSimulatorService
	) {}
}
