import { Inject, Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { API_SERVICE, IApiService, LoanRequestEntityService } from '@oper-client/shared/data-access';
import { Guarantee } from '@oper-client/shared/data-model';

@Injectable()
export class GuaranteesService implements LoanRequestEntityService<Guarantee> {
	constructor(@Inject(API_SERVICE) private apiService: IApiService) {}

	getAll(loanRequestId: number, params: HttpParams = new HttpParams()): Observable<Guarantee[]> {
		return this.apiService.get(`/api/loan-requests/${loanRequestId}/guarantees/`, params);
	}

	get(loanRequestId: number, id: number, params: HttpParams = new HttpParams()): Observable<Guarantee> {
		return this.apiService.get(`/api/loan-requests/${loanRequestId}/guarantees/${id}/`, params);
	}

	create(loanRequestId: number, guarantee: Partial<Guarantee>, params: HttpParams = new HttpParams()): Observable<Guarantee> {
		return this.apiService.post(`/api/loan-requests/${loanRequestId}/guarantees/`, guarantee, params);
	}

	update(loanRequestId: number, id: number, guarantee: Partial<Guarantee>, params: HttpParams = new HttpParams()): Observable<Guarantee> {
		return this.apiService.patch(`/api/loan-requests/${loanRequestId}/guarantees/${id}/`, guarantee, params);
	}

	delete(loanRequestId: number, id: number): Observable<void> {
		return this.apiService.delete(`/api/loan-requests/${loanRequestId}/guarantees/${id}/`);
	}
}
