<div class="input-group oper-async-input-group">
	@if (iconName()) {
		<div class="input-group-addition input-group-addition--prepend">
			@if (!isAsyncSearchLoading()) {
				<oper-client-fontawesome-icon class="icon" [icon]="iconName()" />
			} @else {
				<oper-client-fontawesome-icon class="icon" [spin]="true" icon="faSpinner" />
			}
		</div>
	} @else {
		<div class="input-group-addition input-group-addition--append">
			@if (isAsyncSearchLoading()) {
				<oper-client-fontawesome-icon class="icon" [spin]="true" icon="faSpinner" />
			}
		</div>
	}
	<ng-select
		#select
		type="text"
		class="input-group__form-item-input oper-client-async-select"
		[bindLabel]="bindLabel()"
		[bindValue]="bindValue()"
		[class.hide-arrow]="hideArrow()"
		[items]="asyncSearchResult()"
		[(ngModel)]="selectedValue"
		[placeholder]="placeholder() | translate"
		[clearAllText]="'ç.misc.action.clear' | translate"
		[virtualScroll]="true"
		[compareWith]="compareObjects"
		[openOnEnter]="false"
		[bufferAmount]="5"
		[notFoundText]="noFoundTextLabel() | translate"
		[isOpen]="asyncSearchResult().length > 0 || dropdownCanOpen()"
		(search)="onInputSearch($event)"
		(clear)="onClear()"
		(change)="onChange($event)"
		(focus)="onFocus()"
	>
	</ng-select>
</div>
