import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { API_SERVICE, IApiService, IInterestRateLockDurationConfigService } from '@oper-client/shared/data-access';
import { InterestRateLockDurationConfig } from '@oper-client/shared/data-model';
import { HttpParams } from '@angular/common/http';

@Injectable()
export class InterestRateLockDurationConfigService implements IInterestRateLockDurationConfigService {
	constructor(@Inject(API_SERVICE) private readonly apiService: IApiService) {}

	load(configurationName?: string): Observable<InterestRateLockDurationConfig> {
		let qparams = new HttpParams();
		if (configurationName) {
			qparams = qparams.append('configuration_name', configurationName);
		}
		return this.apiService.get(`/configuration/interest-rate-lock/`, qparams);
	}
}
