export enum PrimaryLoanPurposeEnum {
	PURCHASE = 'purchase',
	NEW_BUILD = 'newBuild',
	BUY_OUT = 'buyOut',
	FREE_USE = 'freeUse',
}

export enum AdditionalLoanPurposeEnum {
	RENOVATION = 'renovation',
	EXTENSION = 'extension',
	MODERNIZATION = 'modernization',
	REFINANCE = 'refinance',
}

export const LoanPurposeEnums = {
	...PrimaryLoanPurposeEnum,
	...AdditionalLoanPurposeEnum,
};

export type LoanPurpose = PrimaryLoanPurposeEnum | AdditionalLoanPurposeEnum;

export const PrimaryLoanPurposes = Object.values(PrimaryLoanPurposeEnum);

export const AdditionalLoanPurposes = Object.values(AdditionalLoanPurposeEnum);
