
import { PurposeConfiguration } from '../models/purpose-config';
import {
	LoanPurposeEnums,
	PartialNormalizedResource,
	ResourceType,
} from '@oper-client/shared/data-model';
import defaultConfiguration from './default.configuration';


const configuration: PurposeConfiguration = {
	presets: (resources: PartialNormalizedResource) => ({
		loanPurposes: resources?.[ResourceType.LOAN_REQUEST_PURPOSE]?.filter((purpose) => purpose.definition === 'purchase'),
	}),
	loanPurposes: [
		LoanPurposeEnums.PURCHASE,
		LoanPurposeEnums.NEW_BUILD,
		LoanPurposeEnums.FREE_USE,
		LoanPurposeEnums.REFINANCE,
		LoanPurposeEnums.RENOVATION,
		LoanPurposeEnums.MODERNIZATION,
		LoanPurposeEnums.EXTENSION,
	],
	loanPurposeCombinations: {
		[LoanPurposeEnums.PURCHASE]: [LoanPurposeEnums.RENOVATION, LoanPurposeEnums.MODERNIZATION, LoanPurposeEnums.EXTENSION],
		[LoanPurposeEnums.RENOVATION]: [LoanPurposeEnums.MODERNIZATION, LoanPurposeEnums.EXTENSION],
		[LoanPurposeEnums.MODERNIZATION]: [LoanPurposeEnums.RENOVATION, LoanPurposeEnums.EXTENSION],
		[LoanPurposeEnums.EXTENSION]: [LoanPurposeEnums.RENOVATION, LoanPurposeEnums.MODERNIZATION],
	},
	loanAndRealtyPurposeMap: {
		...defaultConfiguration.loanAndRealtyPurposeMap
	}
};

export default configuration;
