import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ThirdParty } from '@oper-client/shared/data-model';
import { ThirdPartyService } from './third-party.service';

@Injectable({
	providedIn: 'root',
})
export class NotaryService {
	readonly thirdPartyName = 'notaries';
	constructor(private thirdPartyService: ThirdPartyService) {}

	public getNotary(id: number): Observable<ThirdParty> {
		return this.thirdPartyService.getThirdParty(this.thirdPartyName, id); // Assuming thirdPartyService has this method
	}

	public getNotaries(params: HttpParams = new HttpParams()): Observable<ThirdParty[]> {
		return this.thirdPartyService.getThirdParties(this.thirdPartyName, params);
	}

	public searchNotaries(searchTerm: string, searchFields?: string[]): Observable<ThirdParty[]> {
		return this.thirdPartyService.searchThirdParties(this.thirdPartyName, searchTerm, searchFields);
	}
}
