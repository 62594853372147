<header class="header">
	<div class="header__title">
		{{ title() | translate }}
	</div>

	@if (showDeleteButton()) {
		<div class="header__remove-icon">
			<oper-client-fontawesome-icon [icon]="'faTimes'" [size]="'lg'" (click)="removeCard.emit(key())" />
		</div>
	}
</header>

<oper-client-dynamic-form
	class="form"
	[formConfiguration]="formConfiguration()"
	[debounceTime]="debounceTime()"
	(valueChange)="onFormValueChange($event)"
	(formInitialised)="onFormInitialised($event)"
/>
