@if ($showPlaceholderImg()) {
	<div class="image">
		@if (streetView) {
			<oper-client-fontawesome-icon icon="faHome" size="lg" />
		} @else {
			<oper-client-fontawesome-icon icon="faMapLocationDot" size="lg" />
		}
	</div>
}

<div #streetViewContainer class="street-view--container"></div>



