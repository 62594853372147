import {
	ChangeDetectionStrategy,
	Component,
	DestroyRef,
	ElementRef,
	EventEmitter,
	inject,
	Input,
	OnInit,
	Output,
	signal,
	ViewChild,
} from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { MapConfig } from '@oper-client/shared/data-model';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { debounceTimes } from '@oper-client/shared/configuration';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { GoogleLibrariesService } from '../../services/google-libraries.service';
import { roundNumber } from '@oper-client/shared/util-formatting';
import { deepClone } from '@oper-client/shared/util-object';

@Component({
	selector: 'oper-client-map',
	templateUrl: './map.component.html',
	styleUrls: ['./map.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapComponent implements OnInit {
	@Input() mapConfig$: Observable<MapConfig>;
	@Input() streetView: boolean;
	@Output() locationChanged = new EventEmitter<MapConfig>();
	@ViewChild('streetViewContainer', { static: true }) streetViewContainer: ElementRef;

	$showPlaceholderImg = signal(true);
	mapListener: google.maps.MapsEventListener;
	panoramaChanges$ = new Subject<boolean>();

	private panorama: google.maps.StreetViewPanorama;
	private readonly destroyRef = inject(DestroyRef);
	private readonly googleLibrariesService = inject(GoogleLibrariesService);

	constructor() {
		this.destroyRef.onDestroy(() => {
			if (this.mapListener) {
				this.mapListener.remove();
			}
		});
	}

	ngOnInit(): void {
		this.panoramaChanges$.pipe(
			debounceTime(debounceTimes.xxs),
			takeUntilDestroyed(this.destroyRef)
		).subscribe(() => {
			const coordinates = this.panorama.getLocation().latLng.toJSON();
			const pov = this.panorama.getPov();
			this.locationChanged.emit({
				coordinates: { lat: roundNumber(coordinates.lat, 10), lng: roundNumber(coordinates.lng, 10) },
				pov: {
					heading: roundNumber(pov.heading, 10),
					pitch: roundNumber(pov.pitch, 10),
				},
				zoom: roundNumber(this.panorama.getZoom(), 10),
			});
		});

		this.mapConfig$
			.pipe(
				takeUntilDestroyed(this.destroyRef),
				debounceTime(debounceTimes.s),
				distinctUntilChanged((p, n) => JSON.stringify(p) === JSON.stringify(n)),
				tap((mapConfig) => {
					if (mapConfig) {
						if (mapConfig?.coordinates) {
							this.googleLibrariesService.streetViewService.getPanorama({ location: mapConfig?.coordinates }, (data) => {
								if (data) {
									this.showPanorama(deepClone(mapConfig));
								} else {
									this.hidePanorama();
								}
							});
						} else {
							this.hidePanorama();
						}
					} else {
						this.hidePanorama();
					}
				})
			)
			.subscribe();
	}

	private hidePanorama(): void {
		if (this.panorama) {
			this.panorama.setPano(null);
			this.panorama.setVisible(false);
		}
		this.$showPlaceholderImg.set(true);
	}

	private showPanorama(mapConfig: MapConfig): void {
		if (this.streetView) {
			const config: google.maps.StreetViewPanoramaOptions = {
				visible: false,
				fullscreenControl: true,
				addressControl: false,
				position: mapConfig.coordinates,
			};

			if (mapConfig?.pov) {
				config.pov = mapConfig.pov;
			}

			config.zoom = mapConfig.zoom ?? 0;

			google.maps.event.removeListener(this.mapListener);

			this.panorama = new google.maps.StreetViewPanorama(this.streetViewContainer.nativeElement, config);

			this.panorama.setVisible(true);
			
			let first = true;

			this.mapListener = this.panorama.addListener('pov_changed', () => {
				if (!first) {
					this.panoramaChanges$.next(true);
				}
				first = false;
			});
		} else {
			const map = new google.maps.Map(this.streetViewContainer.nativeElement, {
				center: mapConfig.coordinates,
				zoom: 15,
				mapId: 'DEMO_MAP_ID',
			});

			new google.maps.marker.AdvancedMarkerElement({
				map,
				position: mapConfig.coordinates,
			});
		}

		this.$showPlaceholderImg.set(false);
	}
}
