@if (isVerticalLayout()) {
	<div class="columns">
		<div class="column" *ngFor="let row of rows; let i = index">
			<div class="header__title">
				{{ cardTitle() | translate }}

				<div class="header__remove-text" (click)="!disabled && removeRow$.next(i)">
					<span>
						{{ deleteLabel ? (deleteLabel | translate) : ('ç.misc.action.Remove' | translate) }}
					</span>
				</div>
			</div>

			<oper-client-dynamic-form
				class="column__form"
				[formConfiguration]="row"
				(valueChange)="formChanged$.next({ index: i, form: $event })"
			/>
		</div>
	</div>
} @else {
	@if (cardTitle()) {
		<div class="header__title">
			{{ cardTitle() | translate }}

			@if (showDeleteButton()) {
				<div class="header__remove-icon">
					<oper-client-fontawesome-icon
						[title]="'ç.misc.action.Remove' | translate"
						[icon]="'faTimes'"
						[size]="'lg'"
						(click)="removeCard.emit()"
					/>
				</div>
			}
		</div>
	}
	<header class="header">
		@for (headerDefinition of headerDefinitions; track $index) {
			@if (headerDefinition.class && headerDefinition.class !== 'hidden') {
				<div class="header__item" [ngClass]="headerDefinition.class">
					<div class="header__label">
						{{ headerDefinition.label | translate }}
					</div>

					@if (headerDefinition.helpText) {
						<oper-client-fontawesome-icon
							class="header__icon"
							[tooltip]="headerDefinition.helpText | async | translate"
							[hideDelay]="0"
							[tooltipClass]="'tooltip-break-word'"
							[icon]="'faCircleQuestion'"
						/>
					}
				</div>
			}
		}

		<div class="header__actions"></div>
	</header>
	<div class="rows">
		<div class="row" *ngFor="let row of rows; let i = index">
			<oper-client-dynamic-form
				class="row__form"
				[formConfiguration]="row"
				(valueChange)="formChanged$.next({ index: i, form: $event })"
			/>
			<oper-client-fontawesome-icon
				class="row__icon"
				[ngClass]="{ disabled }"
				[icon]="'faMinus'"
				[size]="'lg'"
				(click)="!disabled && removeRow$.next(i)"
			/>
		</div>
	</div>
}

<button
	*ngIf="!disabled && !isSingleRow"
	class="add-row-button"
	[ngClass]="{ 'reduced-width': isVerticalLayout() }"
	[disabled]="hasInvalidForm$ | async"
	(click)="addRow.emit()"
>
	<oper-client-fontawesome-icon [icon]="'faPlus'" />
	{{ addRowLabel | translate }}
</button>
