import { inject, Inject, Injectable, InjectionToken } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { API_SERVICE, IApiService } from '@oper-client/shared/data-access';

export interface IThirdPartyService {
	searchThirdParties(thirdPartyName: string, searchTerm: string, searchFields?: string[]);
	getThirdParty(thirdPartyName: string, id: number);
}

export const THIRD_PARTY_SERVICE_TOKEN = new InjectionToken<IThirdPartyService>('ThirdPartyService', {
	providedIn: 'root',
	factory: () => new ThirdPartyService(inject(API_SERVICE)),
});

@Injectable({
	providedIn: 'root',
})
export class ThirdPartyService {
	private basePath = '/api/third-parties';
	constructor(@Inject(API_SERVICE) private readonly apiService: IApiService) {}

	public getThirdParties(key: string, params: HttpParams = new HttpParams()): Observable<any> {
		return this.apiService.get(`${this.basePath}/${key}/`, params);
	}

	public getThirdParty(key: string, id: number, params: HttpParams = new HttpParams()): Observable<any> {
		return this.apiService.get(`${this.basePath}/${key}/${id}/`, params);
	}

	public updateThirdParty(key: string, id: number, body: Partial<any>): Observable<any> {
		return this.apiService.patch(`${this.basePath}/${key}/${id}/`, body);
	}

	public searchThirdParties(key: string, searchTerm: string, searchFields?: string[]): Observable<any[]> {
		let params = new HttpParams();
		if (searchTerm) {
			params = params.append('search', searchTerm);

			if (searchFields && searchFields.length > 0) {
				params = params.append('search_fields', searchFields.join(','));
			}
		}
		return this.apiService.get(`${this.basePath}/${key}/`, params);
	}
}
