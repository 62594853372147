import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { AnyObject } from '@oper-client/shared/data-model';

type ColorType = 'default' | 'information' | 'warning' | 'error';

@Component({
	selector: 'oper-client-information-box',
	templateUrl: './information-box.component.html',
	styleUrls: ['./information-box.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InformationBoxComponent {
	@Input() title: string;
	@Input() titleTranslationParams: AnyObject<string>;
	@Input() content: string;
	@Input() contentTranslationParams: AnyObject<string>;
	@Input() color: `${ColorType}` = 'default';
	@Input() icon = 'faCircleExclamation';
	@Input() disableClose = false;
	@Input() isTransparent = false;
	@Output() closed = new EventEmitter<void>();
}
