import { Injectable } from '@angular/core';
import { Action, ActionsSubject, select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map, withLatestFrom } from 'rxjs/operators';

import { ActionState, Offer, parseActionState, ParsedActionState } from '@oper-client/shared/data-model';

import * as OfferSelectors from './offer.selectors';
import * as OfferActions from './offer.actions';
import {
	createOfferFailure,
	createOfferSuccess,
	removeOfferFailure,
	removeOfferSuccess,
	updateOfferFailure,
	updateOfferSuccess,
} from './offer.actions';
import { OfferActionTypes } from './offer.reducer';

@Injectable()
export class OfferFacade {
	offers$ = this.store.pipe(select(OfferSelectors.getOffers));
	currentOffer$ = this.store.pipe(select(OfferSelectors.getCurrentOffer));
	openedOffers$ = this.store.pipe(select(OfferSelectors.getOpenedOffers));
	activeOffer$ = this.store.pipe(select(OfferSelectors.getActiveOffer));

	loadOfferActionState$ = this.selectActionState('loadOffer');
	loadOffersActionState$ = this.selectActionState('loadOffers');
	createOfferActionState$ = this.selectActionState('createOffer');
	removeOfferActionState$ = this.selectActionState('removeOffer');
	updateOfferActionState$ = this.selectActionState('updateOffer');

	readonly createOfferActionSuccess$: Observable<ActionState> = this.createActionStateNotifier(createOfferSuccess.type, 'createOffer');
	readonly createOfferActionError$: Observable<ActionState> = this.createActionStateNotifier(createOfferFailure.type, 'createOffer');
	readonly updateOfferActionSuccess$: Observable<ActionState> = this.createActionStateNotifier(updateOfferSuccess.type, 'updateOffer');
	readonly updateOfferActionError$: Observable<ActionState> = this.createActionStateNotifier(updateOfferFailure.type, 'updateOffer');
	readonly removeOfferActionSuccess$: Observable<ActionState> = this.createActionStateNotifier(removeOfferSuccess.type, 'removeOffer');
	readonly removeOfferActionError$: Observable<ActionState> = this.createActionStateNotifier(removeOfferFailure.type, 'removeOffer');

	constructor(
		private readonly store: Store<any>,
		private readonly actionListener: ActionsSubject
	) {}

	private createActionStateNotifier(actionType: string, stateType: OfferActionTypes): Observable<ActionState> {
		return this.actionListener.pipe(
			filter((_) => _.type === actionType),
			withLatestFrom(this.store),
			map(([, store]) => store.loanRequest.offer.actions[stateType])
		);
	}

	public setCurrentOfferId(offerId: number): void {
		this.store.dispatch(OfferActions.setCurrentOfferId({ offerId }));
	}

	public setOpenedOfferIds(offerIds: number[]): void {
		this.store.dispatch(OfferActions.setOpenedOfferIds({ offerIds }));
	}

	public loadOffer(loanRequestId: number, offerId: number, validation = false) {
		this.store.dispatch(OfferActions.loadOffer({ loanRequestId, offerId, validation }));
	}

	public loadOffers(loanRequestId: number, params: { [key: string]: string } = {}) {
		this.store.dispatch(OfferActions.loadOffers({ loanRequestId, params }));
	}

	public clearOffers() {
		this.store.dispatch(OfferActions.clearOffers());
	}

	public createOffer(loanRequestId: number, offer: Partial<Offer> = {}) {
		this.store.dispatch(OfferActions.createOffer({ loanRequestId, offer }));
	}

	public updateOffer(loanRequestId: number, offer: Partial<Offer>) {
		this.store.dispatch(OfferActions.updateOffer({ loanRequestId, offer }));
	}

	public removeOffer(loanRequestId: number, offerId: number) {
		this.store.dispatch(OfferActions.removeOffer({ loanRequestId, offerId }));
	}

	public dispatch(action: Action): void {
		this.store.dispatch(action);
	}

	private selectActionState(actionType: OfferActionTypes): Observable<ParsedActionState> {
		return this.store.pipe(select(OfferSelectors.getActionState(actionType)), map(parseActionState));
	}
}
