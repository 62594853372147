import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { ThirdParty } from '@oper-client/shared/data-model';

// load all notaries
export const loadNotaries = createAction('[Notary] Load Notaries');
export const loadNotariesSuccess = createAction('[Notary] Load Notaries Success', props<{ notaries: ThirdParty[] }>());
export const loadNotariesFailure = createAction('[Notary] Load Notaries Failure', props<{ error: HttpErrorResponse }>());

//search notaries
export const searchNotaries = createAction('[Notary] Search Notaries', props<{ searchTerm: string; searchFields?: string[] }>());
export const searchNotariesSuccess = createAction('[Notary Search Notaries Success', props<{ notaries: ThirdParty[] }>());
export const searchNotariesFailure = createAction('[Notary] Search Notaries Failure', props<{ error: HttpErrorResponse }>());

//load notary (id)
export const loadNotary = createAction('[Notary] Load Notary', props<{ id: number }>());
export const loadNotarySuccess = createAction('[Notary] Load Notary Success', props<{ notary: ThirdParty }>());
export const loadNotaryFailure = createAction('[Notary] Load Notary Failure', props<{ error: HttpErrorResponse }>());
